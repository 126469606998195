<script setup lang="ts">
const sitedata: any = useSiteData()

const { t, locale }: any = useI18n()
const localePath = useLocalePath()

const route = useRoute()

const scriptData: any = []

if (sitedata.value?.script_src) {
  scriptData.push({
    async: true,
    src: sitedata.value?.script_src,
    tagPosition: 'head',
  })
}

if (sitedata.value?.script_text) {
  scriptData.push({
    innerHTML: sitedata.value?.script_text,
    tagPosition: 'head',
  })
}

useHead({
  script: scriptData,
})

// const slug = route.params.slug ?? ''
const slug = computed(() => _get(route.params.slug ?? [], 0, ''))

const routeNameCheck = computed(() => _includes(String(route.name), 'index'))

const sitedataMenus = computed(() => sitedata.value?.category?.length ? [...[{ name: t('homepage') }], ...sitedata.value?.category] : [])

const mobileMenu = ref<any>(null)
const mobileMenuIcon1 = ref<any>(null)
const mobileMenuIcon2 = ref<any>(null)
const mobileMenuStatus = ref<any>(false)

function handleMobileMenu() {
  if (!mobileMenuStatus.value) {
    mobileMenu.value.classList.remove('hidden')
    mobileMenuIcon1.value.classList.add('hidden')
    mobileMenuIcon2.value.classList.remove('hidden')
    mobileMenuStatus.value = true
  }
  else {
    mobileMenu.value.classList.add('hidden')
    mobileMenuIcon1.value.classList.remove('hidden')
    mobileMenuIcon2.value.classList.add('hidden')
    mobileMenuStatus.value = false
  }
}
</script>

<template>
  <header class="fixed w-full z-[200]">
    <nav class="bg-white border-b border-gray-200 py-2">
      <div class="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
        <NuxtLinkLocale v-if="sitedata?.logo && checkImage(sitedata?.logo)" to="/" aria-label="logo" :hreflang="locale" class="flex items-center">
          <span class="sr-only">Logo</span>
          <img :src="sitedata?.logo" alt="" class="h-10 mr-3 sm:h-12">
        </NuxtLinkLocale>
        <div class="flex items-center lg:order-2">
          <!-- <div class="hidden mt-2 mr-4 sm:inline-block">
                <a class="github-button" href="https://github.com/" data-size="large" data-icon="octicon-star" data-show-count="true" aria-label="Star themesberg/landwind on GitHub">Star</a>
              </div> -->
          <!-- <a href="#" class="text-gray-800 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 focus:outline-none">Log in</a> -->
          <!-- <a href="#" class="text-white bg-green-500 hover:bg-green-400 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none">Download</a> -->
          <LangOnly />
          <button type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:bg-gray-100" @click="handleMobileMenu">
            <span class="sr-only">Open main menu</span>
            <svg ref="mobileMenuIcon1" class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" /></svg>
            <svg ref="mobileMenuIcon2" class="hidden w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>
          </button>
        </div>
        <div ref="mobileMenu" class="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1">
          <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
            <li
              v-for="(item, index) in sitedataMenus"
              :key="`sAIUgv${index}`"
            >
              <NuxtLinkLocale
                :to="item?.type === 'external' ? item?.url : (item?.type === 'internal' ? localePath(item?.url) : (item?.slug ? `/${item?.slug}` : '/'))"
                :target="item?.type === 'external' ? '_blank' : ''"
                :hreflang="locale"
                class="block py-2 pl-3 pr-4 font-bold text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-green-500 lg:p-0"
                :class="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? 'block py-2 pl-3 pr-4 text-white bg-green-500 rounded lg:bg-transparent lg:text-green-500 lg:p-0' : ''"
              >
                {{ item?.name }}
              </NuxtLinkLocale>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
